import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet-async";
// import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionPromo1 from "components/SectionPromo1";
import SectionHero2 from "components/SectionHero/SectionHero2";
// import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionSliderProductCard2 from "components/SectionSliderProductCard2";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
// import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
// import Heading from "components/Heading/Heading";
//import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";
import CategoryCollection from 'containers/CategoryCollection';
import { Link } from 'react-router-dom';


//images
import sareers from '../../images/collections/sareers.png'
import tshirt from '../../images/collections/tishirt.png'
import corsets from '../../images/collections/Corsets.png'
import shirt from '../../images/collections/shirt.png'
import kurtis from '../../images/collections/kurti.png'

function PageHome() {

  const [page, setPage] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const sendRequestRef = useRef(true);

  useEffect(() => {
    setLoading(true);
    sendRequestRef.current = true;
    setPage(1);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (sendRequestRef.current === true) {
          sendRequestRef.current = false;
          setLoading(true);
          setPage((prevPage: any) => prevPage + 1);
          sendRequestRef.current = true;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 375 && !loading) {
        if (page != '') {
          fetchData();
        }
      }
    };

    if ((page == 1 || page == 2)) {
      if (page != '') {
        fetchData();
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, page]);

  useEffect(() => {
    if (page == 11) {
      console.log('in page', page)
      const script = document.createElement('script');
      script.id = 'EmbedSocialHashtagScript';
      script.src = 'https://embedsocial.com/cdn/ht.js';

      if (!document.getElementById('EmbedSocialHashtagScript')) {
        document.getElementsByTagName('head')[0].appendChild(script);
      }

      return () => {
        // Cleanup script when the component is unmounted
        const scriptElement = document.getElementById('EmbedSocialHashtagScript');
        if (scriptElement) {
          scriptElement.remove();
        }
      };
    }
  }, [page]);


  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Tikasaheb | Buy Traditional, Ethnic, & Western Wear For Women</title>
        <meta property="og:site_name" content="Tikasaheb" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content='Welcome to manufacturing, your premier destination for exquisite bath bombs and salts. Immerse yourself in luxury with our handcrafted products, proudly made in the heart of India and delivered to the UAE. Elevate your bathing experience with our meticulously crafted blends, designed to pamper and rejuvenate. Experience the essence of tradition and quality as we bring the artistry of Indian craftsmanship to your self-care routine. Indulge in the beauty of handmade excellence with Tikasaheb.' />
        <meta property="og:title" content="manufacturing" />
        <meta property="og:image" itemProp="image" content="https://backend.thebodybucket.ae/backend/img/logo/logo.png" />
        <meta property="og:image:url" content="https://backend.thebodybucket.ae/backend/img/logo/logo.png" />
        <meta property="og:image:secure_url" content="https://backend.thebodybucket.ae/backend/img/logo/logo.png" />
        <meta property="og:url" content={window.location.origin} />
      </Helmet>
      {/* SECTION HERO */}
      {page > 1 && <SectionHero2 />}

      {/* --- skeletant-design --- */}
      <div className="skeletant-target hidden">
        <div className="skeletant-design ld-no-radius h-[380px] w-full mb-8"></div>
      </div>
      {/* --- skeletant-design --- */}

      {page > 2 &&
        <div className="mt-12 mb-10 md:mt-14 md:mb-14  lg:mt-14 lg:mb-14  xl:mt-16 xl:mb-16">
          {/* <DiscoverMoreSlider /> */}

          {/*static product cagegoary start*/}
          <div className='container'>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
              <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1">
                <Link to={'/'} className="relative group overflow-hidden rounded-0 cursor-pointer">
                  <img
                    className="h-[274px] w-full object-cover max-w-full rounded-0 transform transition duration-500 ease-in-out group-hover:scale-110"
                    src={tshirt}
                    alt=""
                  />
                  <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-30"></div>
                  <span className="absolute right-[5px] bottom-[5px] bg-white text-dark text-sm   px-2 py-2 transition duration-500 ease-in-out group-hover:bg-[#fece40]">
                    T-Shirts
                  </span> 
                </Link>
                <Link to={'/'} className="relative group overflow-hidden rounded-0 cursor-pointer">
                  <img
                    className="h-[274px] w-full object-cover max-w-full  transform transition duration-500 ease-in-out group-hover:scale-110"
                    src={kurtis}
                    alt=""
                  />
                  <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-30"></div>
                  <span className="absolute right-[5px] bottom-[5px] bg-white text-dark text-sm  px-2 py-2 transition duration-500 ease-in-out group-hover:bg-[#fece40]">
                    Kurtis
                  </span>
                </Link>
              </div>
              <div className="grid gap-4">
                <Link to={'/'} className="relative group overflow-hidden rounded-0 cursor-pointer">
                  <img
                    className="h-full w-full object-cover max-w-full transform transition duration-500 ease-in-out group-hover:scale-110"
                    src={sareers}
                    alt=""
                  />
                  <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-30"></div>
                  <span className="absolute right-[5px] bottom-[5px] bg-white text-dark text-sm  px-2 py-2 transition duration-500 ease-in-out group-hover:bg-[#fece40]">
                    Sarees
                  </span>
                </Link>

              </div>
              <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1">
                <Link to={'/'} className="relative group overflow-hidden rounded-0 cursor-pointer">
                  <img
                    className="h-[274px] w-full object-cover max-w-full  transform transition duration-500 ease-in-out group-hover:scale-110"
                    src={shirt}
                    alt=""
                  />
                  <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-30"></div>
                  <span className="absolute right-[5px] bottom-[5px] bg-white text-dark text-sm  px-2 py-2 transition duration-500 ease-in-out group-hover:bg-[#fece40]">
                    Shirts
                  </span>
                </Link>
                <Link to={'/'} className="relative group overflow-hidden rounded-0 cursor-pointer">
                  <img
                    className="h-[274px] w-full object-cover max-w-full  transform transition duration-500 ease-in-out group-hover:scale-110"
                    src={corsets}
                    alt=""
                  />
                  <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-30"></div>
                  <span className="absolute right-[5px] bottom-[5px] bg-white text-dark text-sm  px-2 py-2 transition duration-500 ease-in-out group-hover:bg-[#fece40]">
                    Corsets
                  </span>
                </Link>
              </div>
            </div>

          </div>
          {/*static product categoary end*/}


        </div>
      }

      <div className="relative mt-7 mb-10 md:mt-12 md:mb-12 lg:mt-12 lg:mb-12    ">
        {/* SECTION */}

        {/* { page > 3 && 
          <SectionSliderProductCards
            data={[
              PRODUCTS[4],
              SPORT_PRODUCTS[5],
              PRODUCTS[7],
              SPORT_PRODUCTS[1],
              PRODUCTS[6],
            ]}
          />
        } */}

        {/* <div className="py-24 lg:py-32 border-t border-b border-slate-200"> */}
        {/* { page > 4 && <SectionHowItWork /> } */}
        {/* </div> */}

        {/* SECTION */}
        {/* <SectionPromo1 /> */}



        {/* SECTION */}
        {/* { page > 5 && <SectionGridFeatureItems /> } */}


        {page > 5 && <CategoryCollection />}

        {/* SECTION */}

        {/*  */}

        {/*  */}
        {/* <div className="container ">
          {page > 6 && <SectionPromo2 />}
        </div> */}



        {/* SECTION 3 */}
        {/* { page > 7 && <SectionSliderLargeProduct cardStyle="style2" /> } */}

        {/*  */}
        <div className='container mb-8 sm:mb-12 md:mb-16'>
          {page > 8 && <SectionSliderCategories />}
        </div>

        {/* SECTION */}
        {/* <div className='container'>
        <SectionPromo3 />
        </div> */}

        {page > 9 &&
          <div className='container'>
            <SectionSliderProductCard2
              heading="Best Sellers"
              subHeading="Best selling of the month"
            />
          </div>
        }

        <div className=""></div>

        <div>
          {/* {page > 10 && (
            <div className="relative pt-16 pb-0 lg:pt-16 lg:pb-0 hidden">
              <BackgroundSection />
              <div>
                <div className="relative z-1 text-center text-custom-pink  text-3xl md:text-4xl font-semibold">
                  Instagram Feed
                </div>
                <div style={{ paddingBottom: "30px" }} className="embedsocial-hashtag" data-ref="f57df33976844ee7935c83d2f44805f998f44d2b"></div>
                <div className="flex mt-16 justify-center"></div>
              </div>
            </div>
          )} */}
          <div className='testimonial my-16'>
            <div className='container'>
              {page > 11 && <SectionClientSay />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHome;
