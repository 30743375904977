import React, { FC, useEffect, useState, useId } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory2 from "components/CardCategories/CardCategory2";
import department1Png from "images/collections/department1.png";
import department2Png from "images/collections/department2.png";
import department3Png from "images/collections/department3.png";
import department4Png from "images/collections/department4.png";
import { Link } from "react-router-dom";
import axios from '../../contains/axios';
import API_HOST from '../../config/config';

export interface CardCategoryData {
  name: string;
  desc: string;
  img: string;
  color?: string;
}
const CATS: CardCategoryData[] = [
  {
    name: "Jewelry",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100",
  },
  {
    name: "Beauty Products",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100",
  },
  {
    name: "Bags",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100",
  },
  {
    name: "Fashion",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100",
  },
];
export interface SectionSliderCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
}

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
  heading = "Shop by department",
  subHeading = "",
  className = "",
  itemClassName = "",
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  const [headerCategory, setHeaderCategory] = useState([]);
  useEffect(() => {
    const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-category-list',{
          nopaginate : '1',
          status : '1',
          order : 'asc',
          _token: 'asdsasa',
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setHeaderCategory(response.data.data.categories);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4,
        },
        1023: {
          gap: 20,
          perView: 3,
        },
        768: {
          gap: 20,
          perView: 3,
        },
        640: {
          gap: 20,
          perView: 2.3,
        },
        500: {
          gap: 20,
          perView: 1.4,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [headerCategory,UNIQUE_CLASS]);

  return (
    <>
    <div className={`nc-SectionSliderCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading desc={subHeading} hasNextPrev>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {headerCategory.map((item : any, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <CardCategory2
                  url={item.href}
                  featuredImage={item.image}
                  name={item.name}
                  desc={((item.product_count) > 0 ? (item.product_count+'+ products') : '')}
                  bgClass={item.color}
                />
              </li>
            ))}
            <li className={`hidden glide__slide ${itemClassName}`}>
              {/* <div
                className={`flex-1 relative w-full h-0 rounded-0 overflow-hidden group aspect-w-1 aspect-h-1 bg-slate-100`}
              > */}
                <div
                className={`flex-1 relative w-full h-0 rounded-0 overflow-hidden group aspect-w-1 aspect-h-1 bg-slate-100`}
              >
                <div>
                  <div className="absolute inset-y-6 inset-x-10 flex flex-col sm:items-center justify-center">
                    <div className="flex relative text-slate-900">
                      <span className="text-lg font-semibold ">
                        More collections
                      </span>
                      <svg
                        className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M12 20.4999V3.66992"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <span className="text-sm mt-1 text-slate-800">
                      Show me more
                    </span>
                  </div>
                </div>
                <Link
                  to={"/category"}
                  className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"
                ></Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    {/* --- skeletant-design --- */}
    <div className="skeletant-target hidden">
      <div className="skeletant-design ld-no-img h-[30px] max-w-[200px] mt-4 mb-4"></div>
      <div className="grid grid-cols-[1] sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">

        
        <div className="hidden sm:block">
          <div className="skeletant-design h-[250px]"></div>
          <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
          <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
        </div>

        <div className="hidden md:block">
          <div className="skeletant-design h-[250px]"></div>
          <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
          <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
        </div>

        <div className="flex">
          <div className="w-full">
            <div className="skeletant-design h-[250px]"></div>
            <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
            <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
          </div>

          <div className="block md:hidden pl-2 w-[100px]">
            <div className="skeletant-design ld-no-img h-[250px]"></div>
            <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
            <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
          </div>
        </div>

        <div className="hidden lg:block">
          <div className="skeletant-design h-[250px]"></div>
          <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
          <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
        </div>

      </div>
    </div>
    {/* --- skeletant-design --- */}
    </>
  );
};

export default SectionSliderCategories;
