import React, { FC } from "react";

export interface PricesProps {
  className?: string;
  price?: number;      // Original price before discount
  discount?: number;   // Discount percentage
  contentClass?: string;
}

const Prices: FC<PricesProps> = ({
  className = "",
  price = 33,
  discount = 0,       // Default discount to 0
  contentClass = "text-sm font-normal",
}) => {
  // Calculate the discount amount
  const discountAmount = (price * discount) / 100;
  const discountedPrice = price - discountAmount; // Calculate the discounted price

//   return (
//     <div className={`${className}`}>
//       <div className={`flex items-center whitespace-nowrap ${contentClass}`}>
//         <span className="text-slate-400 !leading-none">
          
//           ₹ {discountedPrice.toFixed(0)}.00 &nbsp; {/* Show discounted price */}
//         <del> ₹ {price?.toFixed(0)}.00 </del> {/* Show original price */}
//         </span>
//         {discount > 0 && (
//           <span className="text-green-500 ml-2">-{discount}%</span> // Show discount percentage
//         )}
//       </div>
//     </div>
//   );
// };

  return (
    <div className={`${className}`}>
      <div className={`flex items-center whitespace-nowrap ${contentClass}`}>
        {discount > 0 ? (
          <>
            <span className="text-slate-400 !leading-none">
              ₹ {discountedPrice.toFixed(0)}.00 &nbsp; {/* Show discounted price */}
              <del> ₹ {price.toFixed(0)}.00 </del> {/* Show original price */}
            </span>
            <span className="text-green-500 ml-2">-{discount}%</span> {/* Show discount percentage */}
          </>
        ) : (
          <span className="text-slate-400 !leading-none">
            ₹ {price.toFixed(0)}.00 {/* Show original price only */}
          </span>
        )}
      </div>
    </div>
  );
};

export default Prices;
