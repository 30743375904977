import React, { FC, useState, useEffect, useRef } from "react";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import API_HOST from '../../config/config';
import { Helmet } from "react-helmet-async";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "EMAIL",
    desc: "tikasaheb@gmail.com",
    tdlink: "mailto:tikasaheb@gmail.com",
  },
  {
    title: "PHONE",
    desc: "(+91) 8238230070",
    tdlink: "tel:+91 8238230070",
  },
  {
    title: "ADDRESS",
    desc: "Address: 608, Colonnade - 2, B/H. Rajpath Club, Rajpath Rangoli Road, Bodakdev, Ahmedabad - 380054",
    tdlink: "javascript:void(0);",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [errMsg, setErrMsg] = useState<any[]>([]);
  const [successMsg, setSuccessMsg] = useState<any[]>([]);
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [phoneMsg, setPhoneMsg] = useState<string>('');
  const formRef = useRef<HTMLFormElement>(null);

  const validatePhone = (val: string) => {
    if (val) {
     
    if (!isValidPhoneNumber(val)) {
      return 'Please enter a valid number.';
    }
     
    }
    return '';
  };

  const handleInputChange = (val: string) => {
    setPhoneValue(val || '');
    setPhoneMsg(validatePhone(val || ''));
  };

  useEffect(() => {
    if (formRef.current) {
      ($ as any)(formRef.current).validate({
        rules: {
          first_name: { required: true },
          last_name: { required: true },
          email: { required: true, email: true },
          message: { required: true },
        },
        messages: {
          first_name: { required: 'First Name is required' },
          last_name: { required: 'Last Name is required' },
          email: {
            required: 'Email is required',
            email: 'Invalid email address',
          },
          message: { required: 'Message is required' },
        },
        errorPlacement: (error: any, element: any) => {
          error.addClass('block text-left p-1 text-[14px] text-[#fa826a]');
          error.insertAfter(element);
        },
      });
    }
  }, []);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setErrMsg([]);
  setSuccessMsg([]);

  const phoneError = validatePhone(phoneValue);
  if (phoneError) {
    setPhoneMsg(phoneError);
    return;
  }

  if (formRef.current && formRef.current.checkValidity()) {
    const formData = new FormData(formRef.current);
    const phoneNumber = parsePhoneNumber(phoneValue);
    if (phoneNumber) {
      formData.append('phone', phoneNumber.number);
      if (phoneNumber.country) {
        formData.append('phone_country', phoneNumber.country);
      }
    }

    try {
      const response = await axios.post(`${API_HOST}/api/contact_us_mail`, formData);
      if (response.data.success === 0) {
        formRef.current.reset();
        setPhoneValue('');
        setSuccessMsg([response.data.message]);
      }
    } catch (error: any) { // Cast error to 'any'
      const newArray: any = {};
      if (!error?.response) {
        newArray['server_error'] = "No Server Response";
      } else if (error.response.data.success === 2 && typeof error.response.data.data === 'object') {
        Object.keys(error.response.data.data).forEach((item) => {
          newArray[item] = error.response.data.data[item].toString();
        });
      } else {
        newArray['server_error'] = error.response?.status === 409 ? "Contact us Failed" : "Contact us Failed";
      }
      setErrMsg(newArray);
    }
  }
};


  useEffect(() => {
    if (successMsg.length > 0) {
      const timer = setTimeout(() => setSuccessMsg([]), 10000);
      return () => clearTimeout(timer);
    }
  }, [successMsg]);

  return (
    <div className={`nc-PageContact overflow-hidden ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>Contact || Tikasaheb</title>
      </Helmet>
      <div className="pt-8 pb-8 lg:pb-14 xl:pb-14">
       
        <div className="container mx-auto pb-8">
        <h2 className="my-6 md:my-8 lg:my-10 xl:my-10 text-2xl sm:text-2xl lg:text-4xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-custom-pink text-left">
          Contact
        </h2>
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-6 ">
            <div className="max-w-full space-y-4">
              {info.map((item, index) => (
                <a href={item.tdlink} key={index} className="block border border-neutral-200 hover:bg-neutral-100   p-2 px-4 ">
                  <h3 className="uppercase font-semibold  tracking-wider text-sm md:text-base lg:text-base">
                    {item.title}
                  </h3>
                  <span className="block mt-1 text-neutral-500 text-sm md:text-sm lg:text-sm xl:text-lg">
                    {item.desc}
                  </span>
                </a>
              ))}
            </div>
            <div>
              {errMsg.length > 0 && (
                <div role="alert" className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                  <div>Error</div>
                  <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                    {Object.values(errMsg).map((msg, index) => <p key={index}>{msg}</p>)}
                  </div>
                </div>
              )}
              {successMsg.length > 0 && (
                <div role="alert" className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
                  <div>Success</div>
                  <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                    {Object.values(successMsg).map((msg, index) => <p key={index}>{msg}</p>)}
                  </div>
                </div>
              )}
              <form ref={formRef} onSubmit={handleFormSubmit} encType="multipart/form-data" className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <label className="block">
                  <Label className="text-sm">First name</Label>
                  <Input id="first_name" name="first_name" placeholder="First name" type="text" className="mt-1 h-12" />
                </label>
                <label className="block">
                  <Label className="text-sm">Last name</Label>
                  <Input id="last_name" name="last_name" placeholder="Last name" type="text" className="mt-1 h-12" />
                </label>
                <label className="block">
                  <Label className="text-sm">Email address</Label>
                  <Input id="email" name="email" type="email" placeholder="Enter email address" className="mt-1 h-12" />
                </label>
                <label className="block">
                  <Label className="text-sm">Phone</Label>
                  <PhoneInput
                    inputclass={`block w-full border-neutral-200 focus:border-[#000]  bg-white  disabled:bg-neutral-200  rounded-0 text-sm font-normal h-11 px-4 py-3 mt-1 h-11`}
                    international={false}
                    defaultCountry="IN"
                    value={phoneValue}
                    onChange={handleInputChange}
                  />
                  {phoneMsg && <span className="block text-left p-1 text-[14px] text-[#fa826a]">{phoneMsg}</span>}
                </label>
                <label className="block sm:col-span-2">
                  <Label className="text-sm">Message</Label>
                  <Textarea id="message" name="message" className="mt-1" rows={6} />
                </label>
                <div className="sm:col-span-2 text-center mt-8">
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
